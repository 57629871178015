import React from 'react';

import Icon from './Icon';

export default function UploadIcon({ className = '' }: { className?: string; }): JSX.Element {
  return (
    <Icon text="UploadIcon">
      <svg
        className={className}
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M9 16H11M9 4H11M4 9V11M16 9V11M6 16C4.89543 16 4 15.1046 4 14M14 4C15.1046 4 16 4.89543 16 6M4 6C4 4.89543 4.89543 4 6 4M16 14C16 15.1046 15.1046 16 14 16M7.91667 9.58333L10 7.5M10 7.5L12.0833 9.58333M10 7.5V12.5"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </Icon>
  );
}
