import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import React, { useCallback, useContext, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { openFeedsSubMenu, openSaveDocSubMenu } from 'shared/foreground/cmdPalette';
import { ShortcutId } from 'shared/types/keyboardShortcuts';

import { useKeyboardShortcutPreventDefault } from '../../hooks/useKeyboardShortcut';
import { useShortcutsMap } from '../../utils/shortcuts';
import Button from '../Button';
import { FileUploadContext } from '../FileDropzone';
import StrokeApps from '../icons/20StrokeApps';
import StrokeEllipsis from '../icons/20StrokeEllipsis';
import StrokeBrowserExtension from '../icons/24StrokeBrowserExtension';
import IntegrationsIcon from '../icons/IntegrationsIcon';
import NavEmail from '../icons/NavEmail';
import NavFeed from '../icons/NavFeed';
import NavLinkIcon from '../icons/NavLinkIcon';
import NavTwitter from '../icons/NavTwitter';
import StrokeAddCircleIcon from '../icons/StrokeAddCircleIcon';
import UploadIcon from '../icons/UploadIcon';
import Tooltip from '../Tooltip';
import styles from './AddDropdown.module.css';
import { getSeparatorOption } from './docOptions';
import { Dropdown, DropdownOptionType } from './Dropdown';

export default function AddDropdown({ triggerClassName = '' }: { triggerClassName?: string; }) {
  const [isOpen, setIsOpen] = useState(false);
  const { openFileDialog } = useContext(FileUploadContext);
  const history = useHistory();
  const shortcutsMap = useShortcutsMap();

  useKeyboardShortcutPreventDefault(
    shortcutsMap[ShortcutId.UploadFile],
    useCallback(() => openFileDialog(), [openFileDialog]),
    { description: 'Upload file' },
  );

  const options = useMemo(
    () => [
      {
        type: DropdownOptionType.Item,
        name: 'URL',
        shortcut: shortcutsMap[ShortcutId.OpenSaveDocFromUrlPalette],
        onSelect: openSaveDocSubMenu,
        icon: <NavLinkIcon />,
      },
      {
        type: DropdownOptionType.Item,
        name: 'Upload',
        shortcut: shortcutsMap[ShortcutId.UploadFile],
        onSelect: openFileDialog,
        icon: <UploadIcon />,
      },
      getSeparatorOption(),
      {
        type: DropdownOptionType.Item,
        name: 'Email',
        onSelect: () => history.push('/add-to-library'),
        icon: <NavEmail />,
      },
      {
        type: DropdownOptionType.Item,
        name: 'RSS Feed',
        shortcut: shortcutsMap[ShortcutId.ManageFeedSubscriptions],
        onSelect: openFeedsSubMenu,
        icon: <NavFeed />,
      },
      {
        type: DropdownOptionType.Item,
        name: 'Twitter List',
        shortcut: shortcutsMap[ShortcutId.ManageFeedSubscriptions],
        onSelect: openFeedsSubMenu,
        icon: <NavTwitter />,
      },
      getSeparatorOption(),
      {
        type: DropdownOptionType.Item,
        name: 'Configure integrations',
        onSelect: () => history.push('/integrations'),
        icon: <IntegrationsIcon className={styles.icon} />,
      },
      {
        type: DropdownOptionType.Item,
        name: 'Get browser extension',
        onSelect: () => history.push('/resources'),
        icon: <StrokeBrowserExtension className={styles.icon} />,
      },
      {
        type: DropdownOptionType.Item,
        name: 'Download apps',
        onSelect: () => history.push('/resources'),
        icon: <StrokeApps />,
      },
      getSeparatorOption(),
      {
        type: DropdownOptionType.Item,
        name: 'More options',
        onSelect: () => history.push('/add-to-library'),
        icon: <StrokeEllipsis />,
      },
    ],
    [openFileDialog, history, shortcutsMap],
  );

  return (
    <Dropdown
      appendToDocumentBody
      isOpen={isOpen}
      options={options}
      setIsOpen={setIsOpen}
      side="bottom"
      contentAlignment="start"
      contentClassName={styles.dropdownContent}
      trigger={
        <Tooltip disabled={isOpen} content="Add documents, feed sources, and more" placement="right">
          <DropdownMenu.Trigger asChild>
            <Button
              className={`${triggerClassName} ${styles.triggerElement} ${isOpen ? styles.isOpen : ''}`}
              variant="unstyled"
            >
              <StrokeAddCircleIcon />
            </Button>
          </DropdownMenu.Trigger>
        </Tooltip>
      }
    />
  );
}
