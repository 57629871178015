import React from 'react';

import Icon from './Icon';

export default function StrokeArrowRightIcon({
  className = '',
  text = 'Right',
}: { className?: string; text?: string; }): JSX.Element {
  return (
    <Icon text={text}>
      <svg
        className={className}
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M13.4689 16.4706C13.176 16.7635 13.176 17.2384 13.4689 17.5313C13.7618 17.8242 14.2367 17.8242 14.5296 17.5313L19.5306 12.5303C19.6067 12.4542 19.663 12.3659 19.6995 12.2716C19.7259 12.2039 19.7426 12.1314 19.7482 12.0558C19.7511 12.0171 19.751 11.9782 19.7478 11.9395C19.7426 11.8733 19.7287 11.8094 19.7073 11.7492C19.6711 11.6471 19.6123 11.5513 19.5306 11.4696L14.5296 6.46864C14.2367 6.17575 13.7618 6.17575 13.4689 6.46864C13.176 6.76153 13.176 7.23641 13.4689 7.5293L17.1896 11.25L5.00024 11.25C4.58603 11.25 4.25024 11.5858 4.25024 12C4.25024 12.4142 4.58603 12.75 5.00024 12.75L17.1896 12.75L13.4689 16.4706Z"
          fill="currentColor"
        />
      </svg>
    </Icon>
  );
}
