import React from 'react';

import Icon from './Icon';

export default function NavSettingsIcon({
  className,
  text = 'settings',
}: { className?: string; text?: string; }): JSX.Element {
  return (
    <Icon text={text}>
      <svg
        className={className}
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g id="Group">
          <path
            id="Path"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M7.23975 13.9777L7.63441 14.8653C7.87411 15.4051 8.40935 15.7529 8.99998 15.7529V15.7529C9.59062 15.7529 10.1259 15.4051 10.3656 14.8653L10.7602 13.9777C10.9007 13.6627 11.137 13.4002 11.4355 13.2274V13.2274C11.7359 13.0541 12.0833 12.9803 12.4282 13.0165L13.3938 13.1193C13.9811 13.1815 14.5498 12.8918 14.8449 12.3803V12.3803C15.1404 11.8691 15.1069 11.2317 14.7594 10.7544L14.1876 9.96878C13.9841 9.68698 13.8753 9.34777 13.877 9.00013V9.00013C13.877 8.65343 13.9867 8.31563 14.1906 8.03523L14.7624 7.24965C15.1099 6.77226 15.1434 6.13493 14.8479 5.62372V5.62372C14.5528 5.11221 13.9841 4.82255 13.3968 4.88466L12.4312 4.98746C12.0863 5.02367 11.7389 4.94987 11.4385 4.77662V4.77662C11.1394 4.60287 10.903 4.3389 10.7632 4.02255L10.3656 3.13493C10.1259 2.59512 9.59062 2.24731 8.99998 2.24731V2.24731C8.40935 2.24731 7.87411 2.59512 7.63441 3.13493L7.23975 4.02255C7.09992 4.3389 6.86353 4.60287 6.56447 4.77662V4.77662C6.26411 4.94987 5.91665 5.02367 5.57181 4.98746L4.60315 4.88466C4.01589 4.82255 3.44715 5.11221 3.15205 5.62372V5.62372C2.85656 6.13493 2.89009 6.77226 3.23758 7.24965L3.80932 8.03523C4.01322 8.31563 4.12301 8.65343 4.12295 9.00013V9.00013C4.12301 9.34682 4.01322 9.68463 3.80932 9.96503L3.23758 10.7506C2.89009 11.228 2.85656 11.8653 3.15205 12.3765V12.3765C3.44743 12.8878 4.01596 13.1773 4.60315 13.1156L5.5688 13.0128C5.91365 12.9766 6.26111 13.0504 6.56147 13.2236V13.2236C6.86165 13.3969 7.09913 13.6609 7.23975 13.9777V13.9777Z"
            stroke="currentColor"
            strokeWidth="1.5"
          />
          <circle
            id="Oval"
            cx="8.99999"
            cy="8.99999"
            r="1.98608"
            stroke="currentColor"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
      </svg>
    </Icon>
  );
}
