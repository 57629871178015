import React from 'react';

import Icon from './Icon';

export default function FeedIcon({
  className = '',
  text = 'Feed',
}: { className?: string; text?: string; }): JSX.Element {
  return (
    <Icon text={text}>
      <svg
        className={className}
        width="20px"
        height="20px"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M1.25001 2C1.25001 1.58579 1.58579 1.25 2.00001 1.25C2.01835 1.25 2.0367 1.25003 2.05504 1.25009C6.47392 1.2646 10.7215 3.02555 13.8481 6.1521C16.9876 9.29163 18.75 13.5614 18.75 18C18.75 18.4142 18.4142 18.75 18 18.75C17.5858 18.75 17.25 18.4142 17.25 18C17.25 17.9338 17.2496 17.8677 17.2487 17.8017C17.1968 13.8329 15.5901 10.0365 12.7874 7.21278C9.91664 4.36336 6.04056 2.75 2.00001 2.75C1.58579 2.75 1.25001 2.41421 1.25001 2ZM1.25 6.56974C1.25 6.15553 1.58579 5.81974 2 5.81974C2.03549 5.81974 2.07096 5.8199 2.10642 5.82021C5.29503 5.84808 8.35757 7.12779 10.6149 9.38511C12.8973 11.6675 14.1803 14.7732 14.1803 18C14.1803 18.4142 13.8445 18.75 13.4303 18.75C13.016 18.75 12.6803 18.4142 12.6803 18C12.6803 17.9412 12.6798 17.8824 12.6788 17.8236C12.6329 15.0586 11.5086 12.415 9.55422 10.4458C7.5432 8.44992 4.82878 7.31974 2 7.31974C1.58579 7.31974 1.25 6.98395 1.25 6.56974ZM1.2502 11.1395C1.2502 10.7253 1.58599 10.3895 2.0002 10.3895C4.01859 10.3895 5.9543 11.1913 7.38151 12.6185C7.38848 12.6255 7.39542 12.6324 7.40236 12.6394C8.81653 14.0646 9.61053 15.9913 9.61053 17.9998C9.61053 18.414 9.27474 18.7498 8.86053 18.7498C8.44631 18.7498 8.11053 18.414 8.11053 17.9998C8.11053 16.3933 7.47343 14.8443 6.34426 13.7027C6.33648 13.6948 6.32868 13.687 6.32085 13.6791C5.19947 12.5578 3.67859 11.9149 2.09508 11.8902C2.06348 11.8897 2.03186 11.8895 2.0002 11.8895C1.58599 11.8895 1.2502 11.5537 1.2502 11.1395Z" />
      </svg>
    </Icon>
  );
}
