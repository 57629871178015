import React from 'react';

import Icon from './Icon';

export default function StrokeApps({
  className = '',
  text = 'StrokeApps',
}: { className?: string; text?: string; }): JSX.Element {
  return (
    <Icon text={text}>
      <svg
        className={className}
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M13.1263 15.0023C12.7811 15.0023 12.5013 15.2822 12.5013 15.6273C12.5013 15.9725 12.7811 16.2523 13.1263 16.2523H15.2105C15.5557 16.2523 15.8355 15.9725 15.8355 15.6273C15.8355 15.2822 15.5557 15.0023 15.2105 15.0023H13.1263Z"
          fill="currentColor"
        />
        <path
          d="M1.875 4.45365C1.875 2.99191 3.12698 1.875 4.58465 1.875H14.582C16.0397 1.875 17.2917 2.99191 17.2917 4.45365V7.84651C17.786 8.07994 18.1281 8.58301 18.1281 9.16597V16.6698C18.1281 17.4752 17.4752 18.1281 16.6698 18.1281H11.667C10.8616 18.1281 10.2086 17.4752 10.2086 16.6698V16.4608H7.07717C7.07352 16.4608 7.06986 16.4608 7.06619 16.4608C7.06368 16.4608 7.06116 16.4608 7.05864 16.4608H5.77159C5.42641 16.4608 5.14659 16.1809 5.14659 15.8358C5.14659 15.4906 5.42641 15.2108 5.77159 15.2108H6.51531L6.77574 13.125H4.58465C3.12698 13.125 1.875 12.0081 1.875 10.5463V4.45365ZM16.0417 4.45365C16.0417 3.75745 15.427 3.125 14.582 3.125H4.58465C3.73968 3.125 3.125 3.75745 3.125 4.45365V10.5463C3.125 11.2426 3.73968 11.875 4.58465 11.875H10.2086V9.16597C10.2086 8.36055 10.8616 7.70763 11.667 7.70763H16.0417V4.45365ZM8.03544 13.125L7.77501 15.2108H10.2086V13.125H8.03544ZM11.4587 15.8358L11.4586 15.8442V16.6698C11.4586 16.7848 11.5519 16.8781 11.667 16.8781H16.6698C16.7848 16.8781 16.8781 16.7848 16.8781 16.6698V9.16597C16.8781 9.05091 16.7848 8.95763 16.6698 8.95763H11.667C11.5519 8.95763 11.4586 9.05091 11.4586 9.16597V15.8273L11.4587 15.8358Z"
          fill="currentColor"
        />
      </svg>
    </Icon>
  );
}
